import { type Locale } from './language'

/**
 * Gets cart ID local storage key.
 */
export const getShopifyCartIdStorageKey = (locale: Locale) =>
  `cart_id_${locale}_v2`

/**
 * Gets checkout URL local storage key.
 */
export const getShopifyCheckoutUrlStorageKey = (locale: Locale) =>
  `checkout_url_${locale}_v2`

/**
 * Gets Shopify redirect count local storage key.
 */
export const getShopifyRedirectCountStorageKey = (locale: Locale) =>
  `shopify_redirect_count_${locale}`
