import { Router } from 'next/router'
import { useContext, useEffect } from 'react'

import { LanguageContext } from '@lib/language-context'
import {
  getShopifyCheckoutUrlStorageKey,
  getShopifyRedirectCountStorageKey,
} from '@lib/local-storage'
import { getLinkPageUrl } from '@lib/routes'

/**
 * Shopify redirect handler hook.
 */
export const useShopifyRedirect = (
  router: Router,
  shopifyRedirect: boolean,
) => {
  const { locale } = useContext(LanguageContext)

  useEffect(() => {
    if (!shopifyRedirect) {
      return
    }

    // Get checkout URL and Shopify redirect count
    const shopifyCheckoutUrlStorageKey = getShopifyCheckoutUrlStorageKey(locale)
    const shopifyRedirectCountStorageKey =
      getShopifyRedirectCountStorageKey(locale)
    const shopifyCheckoutUrl = localStorage.getItem(
      shopifyCheckoutUrlStorageKey,
    )
    const shopifyRedirectCount =
      Number(localStorage.getItem(shopifyRedirectCountStorageKey)) || 0

    // Check that Shopify redirect count is low to prevent endless loops
    if (shopifyCheckoutUrl && shopifyRedirectCount < 3) {
      localStorage.setItem(
        shopifyRedirectCountStorageKey,
        `${shopifyRedirectCount + 1}`,
      )

      //  Redirect to checkout
      window.location.href = shopifyCheckoutUrl
      return
    }

    // Redirect to homepage
    const homePageUrl = getLinkPageUrl('homePage')
    router.push(homePageUrl, homePageUrl, {
      locale,
    })
  }, [locale, shopifyRedirect, router])
}
